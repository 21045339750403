import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _00bf0ada = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _e36c3170 = () => interopDefault(import('../pages/cart.vue' /* webpackChunkName: "pages/cart" */))
const _148ca872 = () => interopDefault(import('../pages/categories/index.vue' /* webpackChunkName: "pages/categories/index" */))
const _565fa217 = () => interopDefault(import('../pages/cookies.vue' /* webpackChunkName: "pages/cookies" */))
const _c7c492f8 = () => interopDefault(import('../pages/events/index.vue' /* webpackChunkName: "pages/events/index" */))
const _6b32a41e = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _60ffce9c = () => interopDefault(import('../pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _fbb57bde = () => interopDefault(import('../pages/payment/index.vue' /* webpackChunkName: "pages/payment/index" */))
const _8316ac18 = () => interopDefault(import('../pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _613cd94b = () => interopDefault(import('../pages/register.vue' /* webpackChunkName: "pages/register" */))
const _722ad899 = () => interopDefault(import('../pages/restore/index.vue' /* webpackChunkName: "pages/restore/index" */))
const _7a3dac60 = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _5abd2b22 = () => interopDefault(import('../pages/tickets.vue' /* webpackChunkName: "pages/tickets" */))
const _0d28a9ee = () => interopDefault(import('../pages/profile/tickets.vue' /* webpackChunkName: "pages/profile/tickets" */))
const _1ca8ad1d = () => interopDefault(import('../pages/categories/_cid/index.vue' /* webpackChunkName: "pages/categories/_cid/index" */))
const _04c4dd82 = () => interopDefault(import('../pages/events/_eid/index.vue' /* webpackChunkName: "pages/events/_eid/index" */))
const _7caad0b2 = () => interopDefault(import('../pages/groups/_id.vue' /* webpackChunkName: "pages/groups/_id" */))
const _7efce4a8 = () => interopDefault(import('../pages/payment/_hash.vue' /* webpackChunkName: "pages/payment/_hash" */))
const _7bb6b94b = () => interopDefault(import('../pages/refund/_id.vue' /* webpackChunkName: "pages/refund/_id" */))
const _ca220fae = () => interopDefault(import('../pages/restore/_key.vue' /* webpackChunkName: "pages/restore/_key" */))
const _3fc8e692 = () => interopDefault(import('../pages/unsubscribe/_hash.vue' /* webpackChunkName: "pages/unsubscribe/_hash" */))
const _270599d2 = () => interopDefault(import('../pages/events/_eid/tickets.vue' /* webpackChunkName: "pages/events/_eid/tickets" */))
const _01ef2cdc = () => interopDefault(import('../pages/_slug.vue' /* webpackChunkName: "pages/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'active',
  scrollBehavior,

  routes: [{
    path: "/en",
    component: _00bf0ada,
    name: "index___en"
  }, {
    path: "/ru",
    component: _00bf0ada,
    name: "index___ru"
  }, {
    path: "/en/cart",
    component: _e36c3170,
    name: "cart___en"
  }, {
    path: "/en/categories",
    component: _148ca872,
    name: "categories___en"
  }, {
    path: "/en/cookies",
    component: _565fa217,
    name: "cookies___en"
  }, {
    path: "/en/events",
    component: _c7c492f8,
    name: "events___en"
  }, {
    path: "/en/login",
    component: _6b32a41e,
    name: "login___en"
  }, {
    path: "/en/logout",
    component: _60ffce9c,
    name: "logout___en"
  }, {
    path: "/en/payment",
    component: _fbb57bde,
    name: "payment___en"
  }, {
    path: "/en/profile",
    component: _8316ac18,
    name: "profile___en"
  }, {
    path: "/en/register",
    component: _613cd94b,
    name: "register___en"
  }, {
    path: "/en/restore",
    component: _722ad899,
    name: "restore___en"
  }, {
    path: "/en/search",
    component: _7a3dac60,
    name: "search___en"
  }, {
    path: "/en/tickets",
    component: _5abd2b22,
    name: "tickets___en"
  }, {
    path: "/ru/cart",
    component: _e36c3170,
    name: "cart___ru"
  }, {
    path: "/ru/categories",
    component: _148ca872,
    name: "categories___ru"
  }, {
    path: "/ru/cookies",
    component: _565fa217,
    name: "cookies___ru"
  }, {
    path: "/ru/events",
    component: _c7c492f8,
    name: "events___ru"
  }, {
    path: "/ru/login",
    component: _6b32a41e,
    name: "login___ru"
  }, {
    path: "/ru/logout",
    component: _60ffce9c,
    name: "logout___ru"
  }, {
    path: "/ru/payment",
    component: _fbb57bde,
    name: "payment___ru"
  }, {
    path: "/ru/profile",
    component: _8316ac18,
    name: "profile___ru"
  }, {
    path: "/ru/register",
    component: _613cd94b,
    name: "register___ru"
  }, {
    path: "/ru/restore",
    component: _722ad899,
    name: "restore___ru"
  }, {
    path: "/ru/search",
    component: _7a3dac60,
    name: "search___ru"
  }, {
    path: "/ru/tickets",
    component: _5abd2b22,
    name: "tickets___ru"
  }, {
    path: "/en/profile/tickets",
    component: _0d28a9ee,
    name: "profile-tickets___en"
  }, {
    path: "/ru/profile/tickets",
    component: _0d28a9ee,
    name: "profile-tickets___ru"
  }, {
    path: "/en/categories/:cid",
    component: _1ca8ad1d,
    name: "categories-cid___en"
  }, {
    path: "/en/events/:eid",
    component: _04c4dd82,
    name: "events-eid___en"
  }, {
    path: "/en/groups/:id?",
    component: _7caad0b2,
    name: "groups-id___en"
  }, {
    path: "/en/payment/:hash",
    component: _7efce4a8,
    name: "payment-hash___en"
  }, {
    path: "/en/refund/:id?",
    component: _7bb6b94b,
    name: "refund-id___en"
  }, {
    path: "/en/restore/:key",
    component: _ca220fae,
    name: "restore-key___en"
  }, {
    path: "/en/unsubscribe/:hash?",
    component: _3fc8e692,
    name: "unsubscribe-hash___en"
  }, {
    path: "/ru/categories/:cid",
    component: _1ca8ad1d,
    name: "categories-cid___ru"
  }, {
    path: "/ru/events/:eid",
    component: _04c4dd82,
    name: "events-eid___ru"
  }, {
    path: "/ru/groups/:id?",
    component: _7caad0b2,
    name: "groups-id___ru"
  }, {
    path: "/ru/payment/:hash",
    component: _7efce4a8,
    name: "payment-hash___ru"
  }, {
    path: "/ru/refund/:id?",
    component: _7bb6b94b,
    name: "refund-id___ru"
  }, {
    path: "/ru/restore/:key",
    component: _ca220fae,
    name: "restore-key___ru"
  }, {
    path: "/ru/unsubscribe/:hash?",
    component: _3fc8e692,
    name: "unsubscribe-hash___ru"
  }, {
    path: "/en/events/:eid/tickets",
    component: _270599d2,
    name: "events-eid-tickets___en"
  }, {
    path: "/ru/events/:eid/tickets",
    component: _270599d2,
    name: "events-eid-tickets___ru"
  }, {
    path: "/en/:slug",
    component: _01ef2cdc,
    name: "slug___en"
  }, {
    path: "/ru/:slug",
    component: _01ef2cdc,
    name: "slug___ru"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
